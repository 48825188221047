import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {all, task, timeout} from 'ember-concurrency';
import {shippingValidations} from 'additive-voucher/validations/shipping';
import {action} from '@ember/object';
import {alias, equal, or} from '@ember/object/computed';
import cloneDeep from 'lodash.clonedeep';
import {tracked} from '@glimmer/tracking';
import {arg} from 'ember-arg-types';
import {bool, func, string} from 'prop-types';


import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

/**
 * Detail modal component to handle editing/creating of a shipping-model.
 *
 * It is multilingual and handles "inside"-editing,
 * which means it goes into editmode by property,
 * without any extra-route or something.
 *
 * It handles translations CRUD as well.
 *
 * It goes into creation mode, when shippingId equals "new".
 *
 * We have to use cloneDeep as the deep reference of the prices Array
 * remains and would cause wrong behaviours.
 *
 * TODO: as we fetch/update the store model when changing translation
 * the list will use the current/last language of the model for outputing info
 * as well. Maybe prevent this behaviour or refetch defaultLanguage before back-routing.
 *
 * TODO: create multilang-modal component and use it here
 */
export default class AvShippingDetailModalComponent extends Component {
    @service authenticatedFetch;
    @service store;
    @service intl;
    @service router;
    @service uiAppSettings;
    @service uiToast;
    @service uiDialog;

    @arg(bool)
    @tracked
    readonly = false;

    @arg(string)
    @tracked shippingId = null;

    @arg(func)
    onClose() {}

    @tracked
    _editModel = null;
    @tracked
    _currentLocale = 'de';
    @tracked
    _changeset = null;
    @tracked
    _availableLanguagesSnapshot = null;
    @tracked
    _pricesInitialSnapshot = null;

    @tracked
    _isEdit = false;
    @tracked
    _isFormTouched = false;
    @tracked
    _isUniqueCountriesError = false;

    @alias('uiAppSettings.languages.contentLanguages') languages;
    @alias('uiAppSettings.languages.defaultLanguage') defaultLanguage;

    @or('getModel.isRunning', 'deleteTranslation.isRunning') isLoading;
    @equal('shippingId', 'new') isNew;

    /**
     * `ui-lang-select`s `publishedLanguages` property,
     * as we not really have publishing by language,
     * but a whole model `publishing` with the `enabled` prop,
     * we return all existing languages when model is enabled, otherwise empty array
     */
    get _publishedLanguages() {
        return this._changeset?.enabled ? this._changeset.availableLanguages : [];
    }

    @task(function* () {
    try {
        let model = null;

        if (this.isNew) {
            model = yield this.store.createRecord('shipping', {
                language: this.defaultLanguage || 'de',
                prices: [],
            });
            this._isEdit = true;
        } else {
            model = yield this.store.findRecord('shipping', this.shippingId, {
                adapterOptions: {
                    currentLocale: this._currentLocale,
                },
                reload: true,
            });
        }

        this.createChangeset(model);
    } catch (error) {
        throw new Error(`[av-shipping-list] fetch shippings ${error}`);
    }
    })
    // eslint-disable-next-line
    .restartable()
    getModel;

    @task(function* () {
        try {
            this._isFormTouched = true;
            yield this._changeset.validate();

            if (!this._changeset.get('isValid')) {
                return;
            }

            // Validate: countries need to be unique
            let allCountries = [];
            let uniqueCountriesError = false;

            const prices = this._changeset.get('prices') || [];
            prices.forEach(price => {
                const countries = price.countries || [];
                for (const country of countries) {
                    if (uniqueCountriesError) return;

                    if (allCountries.includes(country)) {
                        uniqueCountriesError = true;
                        return;
                    }

                    allCountries.push(country);
                }
            });

            if (uniqueCountriesError) {
                this._isUniqueCountriesError = true;
                return;
            }

            //Update the model on save
            const name = this._changeset.get('name');
            const description = this._changeset.get('description');
            this._editModel = Object.assign({}, this._editModel, {prices,name,description});

            // Because of deep-clone, we are losing the ember-data reference, so manually pass props
            this._changeset.execute();
            this._model.setProperties(this._editModel);

            let tasks = [];
            tasks.push(
                this._model.save({
                    adapterOptions: {
                        currentLocale: this._currentLocale,
                    },
                })
            );
            tasks.push(timeout(250));

            const [model] = yield all(tasks);
            this._isEdit = false;

            if (this.isNew) {
                this.router.replaceWith(this.router.currentRouteName, {
                    shipping_id: model.id,
                });
                this.createChangeset(model);
            }

            this.uiToast.showToast({
                title: this.intl.t('toast.success'),
                type: 'success',
            });
        } catch (error) {
            this.uiToast.showToast({
                title: this.intl.t('toast.unexpectedError'),
                type: 'error',
            });
        }
    }).drop()
    saveModel;

    @task(function* (language) {
        try {
            const adapter = this.store.adapterFor('shipping');
            const computedUrl = adapter.buildURL('shipping', this.shippingId);
            let tasks = [];
            tasks.push(
                yield this.authenticatedFetch.fetch(`${computedUrl}/${language}`, {
                    method: 'DELETE',
                })
            );
            tasks.push(timeout(250));

            yield all(tasks);

            /**
             * If current language was the same as the user wants to delete,
             * change to first available language
             */
            if (this._currentLocale === language) {
                const remainingLanguages = this._model.availableLanguages.filter(lang => lang !== language);
                this._currentLocale = remainingLanguages.length ? remainingLanguages[0] : this.defaultLanguage;
            }

            this.getModel.perform();
        } catch (error) {
            this.uiToast.showToast({
                title: this.intl.t('toast.unexpectedError'),
                type: 'error',
            });
        }
    }).drop()
    deleteTranslation;

    createChangeset(model) {
        if (model.default) {
            /* Default items are non-editable */
            return this.router.replaceWith('instance.settings.shipping-package');
        }

        this._pricesInitialSnapshot = cloneDeep(model.prices);
        this._availableLanguagesSnapshot = cloneDeep(model.availableLanguages);

        /**
         * model.toJSON unfortunately does not work, because it ignores the serializer,
         * and we need the serializer for amount normalization.
         * so we have to get each needed model-prop by getProperties
         */
        const modelProps = model.getProperties(
            'name',
            'description',
            'enabled',
            'default',
            'language',
            'availableLanguages',
            'prices'
        );

        const validation = shippingValidations(this.intl);
        this._editModel = cloneDeep(modelProps);
        /* We have to use cloneDeep otherwise the object inside de prices-array would still be referenced */
        this._changeset = new Changeset(this._editModel, lookupValidator(validation), validation);
        this._model = model;
    }

    constructor() {
        super(...arguments);

        if (!this.shippingId) {
            throw new Error('[av-shipping-detail-modal] shippingId missing, aborted.');
        }

        const model = this.store.peekRecord('shipping', this.shippingId);

        if (model) {
            this.createChangeset(model);
        } else {
            this.getModel.perform();
        }

        this._currentLocale = this?.defaultLanguage;
    }

    @action
    _onClose() {
        if (this._isEdit) {
            /* changeset does not recognize an array-change, so we have to self handle prices dirty state */
            if (this._changeset.get("isDirty")) {
                this.uiDialog.showDiscardChangesConfirm(() => {
                    this.onDiscard();
                });
            } else {
                if (this.isNew) {
                    this.router.transitionTo('instance.settings.shipping-package');
                    this._model.deleteRecord();
                }

                if(!this.isDestroying)
                {
                    this._isEdit = false;
                }
            }
        } else {
            this.onClose();
        }
    }

    @action
    onDiscard() {
        if (this.isNew) {
            this.router.transitionTo('instance.settings.shipping-package');
            this._model.deleteRecord();
            return;
        }

        this.createChangeset(this._model);

        this._isEdit = false;
    }

    @action
    onNewLanguage(language) {
        this._isFormTouched = false;
        this._currentLocale = language;
        this._isEdit = true;
        /* Reset multilingual props */
        this._changeset.set('name', '');
        this._changeset.set('description', '');
        this._changeset.set('language', language);
    }

    @action
    changeLocale(language) {
        if (this._currentLocale === language) {
            return;
        }

        this._isFormTouched = false;
        this._currentLocale = language;

        if (this.isNew) {
            this._changeset.set('language', language);
            return;
        }

        this.getModel.perform();
    }

    @action
    addPrice() {
        this._isFormTouched = false;
        const prices = this._changeset.get('prices');
        prices.push({
            countries: [],
            price: 0,
        });
        this._changeset.set('prices', [...prices]);
        this._editModel = Object.assign({}, this._editModel, {prices: [...prices]});
    }

    @action
    onPriceUpdate(arrayIndex, value) {
        const prices = this._changeset.get('prices');
        prices[arrayIndex] = {countries: value.countries, price: parseInt(value.price) };
        this._changeset.set(`prices`, [...prices]);
    }

    @action
    onPriceDelete(arrayIndex) {
        const prices = this._changeset.get('prices');
        prices.splice(arrayIndex, 1);
        this._changeset.set('prices', [...prices]);
        this._editModel = Object.assign({}, this._editModel, {prices: [...prices]});
    }

    @action
    async toggleEnable() {
        if (this._isEdit) {
            throw new Error(
                '[av-shipping-detail-modal] In edit mode not possible, aborted saving due to possible invalid model'
            );
        }
        this._changeset.set('enabled', !this._changeset.get('enabled'));
        await this.saveModel.perform();
    }
}
